/* eslint-disable jsx-a11y/anchor-is-valid */

type ActionProps = {
  action: any
}

const Action = ({action}: ActionProps) => {
  const date = new Date(action.date)

  return (
    <div className='timeline-item'>
      {/* <div className='timeline-line w-40px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
        <div className='symbol-label bg-light'>
          <KTIcon iconName='message-text-2' className='fs-2 text-gray-500' />
        </div>
      </div> */}

      <div className='timeline-content mb-10 mt-n1'>
        <div className='overflow-auto pb-5'>
          <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5'>
            <a href='#' className='fs-5 text-dark text-hover-primary fw-bold w-375px min-w-200px'>
              {action.action}
            </a>

            <div className='min-w-175px pe-2 ms-auto'>
              <span className='badge badge-light text-muted'>
                {action.user.fname} {action.user.lname}
              </span>
            </div>

            <div className='min-w-125px px-2'>
              <span className='badge badge-light-primary'>{date.toLocaleTimeString()}</span>
            </div>
            <div className='min-w-125px px-2'>
              <span className='badge badge-light-primary'>{date.toLocaleDateString()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Action}
