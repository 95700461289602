/* eslint-disable react/jsx-no-target-blank */
import React from "react"
// import {useIntl} from "react-intl"
// import {KTIcon} from "../../../helpers"
// import {AsideMenuItemWithSub} from "./AsideMenuItemWithSub"
import {AsideMenuItem} from "./AsideMenuItem"

export function AsideMenuMain() {
  // const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='color-swatch'
        title={"dashboard"}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          {/* <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span> */}
        </div>
      </div>
      <AsideMenuItem title='dashboard.new_demand' to='/new-demand' icon='add-notepad' />
      <AsideMenuItem title='dashboard.dossier_title' to='/dossier/demands' icon='folder' />
      <AsideMenuItem title='topbar.usermenu_Paiement' to='/paiement' icon='credit-cart' />
      <AsideMenuItem title='topbar.quicklinks_chat' to='/chat' icon='message-text' />
      {/* <AsideMenuItem title='topbar.quicklinks_account' to='/account/overview' icon='user' /> */}
    </>
  )
}
