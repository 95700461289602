const isOwner = (user, receiver) => {
  if (
    user?.id === undefined ||
    user?.id === null ||
    receiver?.id === undefined ||
    receiver?.id === null
  ) {
    return false
  }
  return user.id === receiver.id
}

export default isOwner
