import toast from "react-hot-toast"
import axiosInstance from "../../../../../apis/axios"

export const fetchDemands = (currentPage: number) => {
  return axiosInstance.get(`/patient/appointments?page=${currentPage}`).then((res) => res.data)
}
export const fetchDemandDetails = (id: string | undefined) => {
  return axiosInstance.get(`/patient/appointments/${id}`).then((res) => res.data.data)
}
export const cancelDemand = (id: string | undefined) => {
  return axiosInstance
    .delete(`/patient/appointments/${id}/cancel`)
    .then((res) => res.data.data)
    .catch((err) => {
      toast.error("Quelque chose s'est mal passé")
      console.log(err)
    })
}
