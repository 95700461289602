import {axiosInstance} from "./AuthHelpers"
// import axios from "axios"

// Server should return AuthModel
export async function login(phone: string, password: string) {
  return axiosInstance.post("/login", {
    phone,
    password,
  })
}

export const register = async (data: FormData) => {
  return axiosInstance.post(`/register`, data, {})
}
export const completeAccount = async (data: FormData) => {
  return axiosInstance.post(`/my-account/complete`, data, {})
}

export const verifyOtpApi = async ({otp, token}: {otp: string; token: string}) => {
  return await axiosInstance.post(
    `/verify`,
    {code: otp},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const resendOtpApi = async (phone) => {
  return await axiosInstance.post(`/resend`, {phone: phone})
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function requestPassword(email: string) {
  // return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
  //   email,
  // })
  console.log("inside request", email)
  return axiosInstance.post("/forgot", {email: email})
}

export async function verifyExistence(email: string, token: string) {
  return axiosInstance.post("/verifyExistence", {email: email, token: token})
}

export function reset(
  email: string,
  token: string,
  password: string,
  password_confirmation: string
) {
  return axiosInstance.post("/reset", {
    email: email,
    token: token,
    password: password,
    password_confirmation: password_confirmation,
  })
}

export const logoutApi = async () => {
  return await axiosInstance.post(`/logout`)
}

export const getUserInfo = async () => {
  return await axiosInstance.get(`/my-account`)
}
