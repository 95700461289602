/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react"
import {KTIcon} from "../../../../../../_metronic/helpers"
import {Dropdown1} from "../../../../../../_metronic/partials/content/dropdown/Dropdown1"
import {useIntl} from "react-intl"
import newDemand from "../../../../../../myassets/newDemand.png"
import {UsersListLoading} from "../../../../apps/user-management/users-list/components/loading/UsersListLoading"
import Pagination from "./Pagination"
import {useQueryContext} from "../core/QueryContextProvider"
import {Link, Navigate} from "react-router-dom"
import {motion} from "framer-motion"

const DemandsList: React.FC = () => {
  const {data: demandsApiResponse, isLoading} = useQueryContext()
  const [filter, setFilter] = useState<string>("")
  const [mutatedDemands, setMutatedDemands] = useState<any>([])

  const handleClick = (param1, param2) => {
    window.open(`/result?param1=${param1}&param2=${param2}`, "_blank")
  }
  const intl = useIntl()
  useEffect(() => {
    setMutatedDemands(filteredData(filter, demandsApiResponse))
  }, [filter, demandsApiResponse])

  const filteredData = (filter, data) => {
    const demandsArray = data?.data

    if (!demandsArray) {
      return demandsArray
    }

    if (filter === "") {
      return demandsArray
    }

    if (filter === "1") {
      return demandsArray.filter((demandObj) => demandObj.status === "en attente")
    }
    if (filter === "2") {
      return demandsArray.filter((demandObj) => demandObj.status === "accepté")
    }
    if (filter === "3") {
      return demandsArray.filter((demandObj) => demandObj.status === "annulé")
    }
    if (filter === "4") {
      return demandsArray.filter((demandObj) => demandObj.status === "rejeté")
    }

    return []
  }

  if (isLoading)
    return (
      <div className='card card-xl-stretch mb-xl-8'>
        <div className='card-header align-items-center border-0 mt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold text-dark'>
              {intl.formatMessage({id: "demands.history_title"})}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {" "}
              {intl.formatMessage({id: "demands.history_desc"})}
            </span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <Dropdown1 filter={filter} setFilter={setFilter} />
          </div>
        </div>
        <div className='card-body pt-3' style={{overflow: "scroll"}}>
          <UsersListLoading />
        </div>
      </div>
    )

  if (!demandsApiResponse && mutatedDemands === undefined) {
    return <Navigate to='/error/500' />
  }

  if (demandsApiResponse && mutatedDemands?.length === 0)
    return (
      <motion.div initial={{opacity: 0.8}} animate={{opacity: 1, y: 0}}>
        <div className='card card-xl-stretch mb-xl-8'>
          <div className='card-header align-items-center border-0 mt-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='fw-bold text-dark'>
                {intl.formatMessage({id: "demands.history_title"})}
              </span>
              <span className='text-muted mt-1 fw-semibold fs-7'>
                {intl.formatMessage({id: "demands.history_desc"})}
              </span>
            </h3>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='category' className='fs-2' />
              </button>
              <Dropdown1 filter={filter} setFilter={setFilter} />
            </div>
          </div>
          <div className='card-body pt-3' style={{overflow: "scroll"}}>
            <div className='card-body pt-3 d-flex gap-3 justify-content-center align-items-center flex-column mt-10'>
              <h1 style={{width: "fit-content"}}>
                {intl.formatMessage({id: "demands.noDemands"})}
              </h1>
              <img
                style={{width: "fit-content"}}
                className='w-25'
                src={newDemand}
                alt='no demand found'
              />
            </div>
            {/* {!isLoading && <Pagination data={demandsApiResponse} />} */}
          </div>
        </div>
      </motion.div>
    )
  if (demandsApiResponse && mutatedDemands?.length !== 0)
    return (
      <motion.div
        initial={{opacity: 0.5, y: 0}}
        animate={{opacity: 1, y: 0}}
        transition={{
          duration: 0.4,
        }}
      >
        <div className='card card-xl-stretch mb-xl-8'>
          <div className='card-header align-items-center border-0 mt-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='fw-bold text-dark'>
                {intl.formatMessage({id: "demands.history_title"})}
              </span>
              <span className='text-muted mt-1 fw-semibold fs-7'>
                {intl.formatMessage({id: "demands.history_desc"})}
              </span>
            </h3>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='category' className='fs-2' />
              </button>
              <Dropdown1 filter={filter} setFilter={setFilter} />
            </div>
          </div>
          <div className='card-body pt-3' style={{overflow: "scroll"}}>
            <table className='table table-row-dashed table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bolder  text-gray-600'>
                  <th>{intl.formatMessage({id: "demands.table.patient"})}</th>
                  <th>{intl.formatMessage({id: "demands.table.exam"})}</th>
                  <th>{intl.formatMessage({id: "demand.selectedService"})}</th>
                  <th>{intl.formatMessage({id: "Médecin_Traitant"})}</th>
                  <th>{intl.formatMessage({id: "Médecin_Radiologue"})}</th>
                  <th>{intl.formatMessage({id: "demands.table.status"})}</th>
                  <th>{intl.formatMessage({id: "demands.table.payStatus"})}</th>
                  <th>{intl.formatMessage({id: "resultat"})}</th>
                  <th>{intl.formatMessage({id: "demands.table.action"})}</th>
                </tr>
              </thead>
              <tbody>
                {mutatedDemands?.map((app) => (
                  <>
                    <tr key={app.id} className='align-middle'>
                      <td>
                        {app.isOwner === "0" ? (
                          <p className='fw-bolder d-flex flex-row  gap-2 align-items-center'>
                            <KTIcon iconName='people' className='fs-2 pe-1' />
                            {app.fname} {app.lname} ({app.relationship})
                          </p>
                        ) : (
                          <p className='d-flex fw-bolder flex-row  gap-2 align-items-center'>
                            <KTIcon iconName='user' className='fs-2 pe-1' />
                            {intl.formatMessage({id: "Propriétaire"})}
                          </p>
                        )}
                      </td>
                      <td>
                        <div className='my-auto'>
                          {app.exam ? (
                            <p className='fw-bolder bg-light-info px-4 py-2 rounded-1'>
                              {app.exam.name}
                            </p>
                          ) : (
                            <span className='fw-bolder bg-light-danger px-4 py-2 rounded-1'>
                              <span className='fw-bolder bg-light-danger px-4 py-2 rounded-1'>
                                {intl.formatMessage({id: "N/A"})}
                              </span>
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className='my-auto'>
                          {app.exam ? (
                            <p className='fw-bolder bg-light-info px-4 py-2 rounded-1'>
                              {app.selected_service}
                            </p>
                          ) : (
                            <span className='fw-bolder bg-light-danger px-4 py-2 rounded-1'>
                              <span className='fw-bolder bg-light-danger px-4 py-2 rounded-1'>
                                {intl.formatMessage({id: "N/A"})}
                              </span>
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        {app.doctor_fname ? (
                          <p className='fw-bolder bg-light-info px-4 py-2 rounded-1'>
                            {app.doctor_fname} {app.doctor_lname}
                          </p>
                        ) : (
                          <div className='fw-bolder bg-light-danger px-4 py-2 rounded-1'>
                            <p className='fw-bolder bg-light-danger px-4 py-2 rounded-1'>
                              {intl.formatMessage({id: "N/A"})}
                            </p>
                          </div>
                        )}
                      </td>

                      <td>
                        {app.radio_doctor ? (
                          <p className='fw-bolder bg-light-info px-4 py-2 rounded-1'>
                            {app.radio_doctor.fname} {app.radio_doctor.lname}
                          </p>
                        ) : (
                          <p className='fw-bolder bg-light-danger px-4 py-2 rounded-1'>
                            <span className='fw-bolder bg-light-danger px-4 py-2 rounded-1'>
                              {intl.formatMessage({id: "N/A"})}
                            </span>
                          </p>
                        )}
                      </td>
                      <td>
                        <StatusColumn value={app?.status} />
                      </td>
                      <td>
                        <PaymentStatusColumn value={app?.payment?.status} />
                      </td>
                      <td>
                        {/* here */}
                        {app.patient.patient_code && app.exam_code ? (
                          <a
                            href='#'
                            onClick={() => handleClick(app.patient.patient_code, app.exam_code)}
                          >
                            <div className='bg-light cursor-pointer d-flex align-items-center justify-content-center bg-hover-light-info rounded-1  text-hover-primary'>
                              <KTIcon iconName='calendar-tick' className='fs-1 m-2 ' />
                            </div>
                          </a>
                        ) : (
                          <div className=' d-flex align-items-center justify-content-center bg-hover-light-warning rounded-1 text-hover-primary'>
                            <KTIcon iconName='time' className='fs-1 m-2 ' />
                          </div>
                        )}
                      </td>
                      <td>
                        <Link to={`/dossier/demands/${app.id}`}>
                          <div
                            style={{maxWidth: "fit-content"}}
                            className='bg-light-dark hoverable w-auto  px-4 py-2 rounded-1 d-flex align-items-center gap-3 '
                          >
                            <KTIcon iconName='eye' className='fs-1'></KTIcon>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
            {!isLoading && <Pagination data={demandsApiResponse} />}
          </div>
        </div>
      </motion.div>
    )

  return null
}

const StatusColumn = ({value}) => {
  const intl = useIntl()

  if (value === "en attente") {
    return (
      <div className='badge badge-light-info fw-bolder p-4'>
        {intl.formatMessage({id: "pending"})}
      </div>
    )
  }

  if (value === "rejeté") {
    return (
      <div className='badge badge-light-danger fw-bolder p-4'>
        {intl.formatMessage({id: "rejected"})}
      </div>
    )
  }
  if (value === "accepté") {
    return (
      <div className='badge badge-light-success fw-bolder p-4'>
        {intl.formatMessage({id: "accepted"})}
      </div>
    )
  }
  if (value === "annulé") {
    return (
      <div className='badge badge-light-warning fw-bolder p-4'>
        {intl.formatMessage({id: "cancelled"})}
      </div>
    )
  }
  return (
    <span className='fw-bolder bg-light-danger px-4 py-2 rounded-1'>
      {intl.formatMessage({id: "N/A"})}
    </span>
  )
}
export const PaymentStatusColumn = ({value}) => {
  const intl = useIntl()

  if (value == "0") {
    return (
      <div className='badge badge-light-warning fw-bolder p-4'>
        {intl.formatMessage({id: "pendingPayment"})}
      </div>
    )
  }

  if (value == "1") {
    return (
      <div className='badge badge-light-success fw-bolder p-4'>
        {intl.formatMessage({id: "Payé"})}
      </div>
    )
  }
  return (
    <div className='badge badge-light-info fw-bolder p-4'>{intl.formatMessage({id: "N/A"})}</div>
  )
}

export default StatusColumn

export {DemandsList}
