/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from "react"
import {Outlet, Link} from "react-router-dom"
import {toAbsoluteUrl} from "../../../_metronic/helpers"
import logo from "../../../myassets/cima-logo.svg"
import authIlustration from "../../../myassets/authIllustration.svg"
import {useIntl} from "react-intl"
const AuthLayout = () => {
  const intl = useIntl()
  useEffect(() => {
    document.body.style.backgroundImage = `none`
    const root = document.getElementById("root")
    if (root) {
      root.style.height = "100%"
    }
    return () => {
      document.body.style.backgroundImage = `url(${toAbsoluteUrl("/media/patterns/header-bg.jpg")})`
      if (root) {
        root.style.height = "auto"
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}

      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            {/* <div id='recaptcha'></div> */}
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='https://www.cima.dz/fr/centre' target='_blank' className='px-5'>
              {intl.formatMessage({id: "aboutUs"})}
            </a>

            <a href='https://cima.dz/contact/' className='px-5' target='_blank'>
              {intl.formatMessage({id: "contactUs"})}
            </a>

            {/* <a href='#' className='px-5' target='_blank'>
              {intl.formatMessage({id: "termsAndConditions"})}
            </a> */}
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 h-full bgi-size-cover bgi-position-center order-1 order-lg-2'
        //  / style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='fade-in-bck d-flex flex-column flex-center py-20 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/'>
            <img alt='Logo' src={logo} className='h-75px ' />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          <div className='auth-background'>
            <img
              className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20 '
              src={authIlustration}
              alt=''
            />
          </div>
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7 text-primary'>
            {intl.formatMessage({id: "excellenceTitle"})}
          </h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-white fs-base text-center text-muted'>
            {intl.formatMessage({id: "coreValues"})}
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
