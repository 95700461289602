/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from "react-intl"
import {toAbsoluteUrl} from "../../../helpers"
import {getNotifications} from "./core/_requests"
import {useQuery} from "react-query"
//defaultAlerts
const HeaderNotificationsMenu = () => {
  const intl = useIntl()
  // useQuery(queryKey, queryFn, { suspense: true })

  const {data} = useQuery({
    queryFn: getNotifications,
    queryKey: ["notifs"],
    suspense: true,
  })

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{
          backgroundImage: `url('${toAbsoluteUrl("/media/misc/menu-header-bg.jpg")}')`,
          backgroundPosition: "top",
        }}
      >
        <h3 className='text-white fw-semibold px-9 mt-10 mb-6'>
          {intl.formatMessage({id: "topbar.notifications_title"})}{" "}
          <span className='fs-8 opacity-75 ps-3'></span>
        </h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold px-9'>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              {intl.formatMessage({id: "topbar.notifications_alert"})}{" "}
            </a>
          </li>
        </ul>
      </div>

      <div className='tab-content '>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {data?.data.map((notif) => (
              <div key={`alert${notif.id}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='mb-0 me-2'>
                    <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      {notif?.content}
                    </a>
                    {/* <div className='text-gray-400 fs-7'>test</div> */}
                  </div>
                </div>
              </div>
            ))}

            {/* {defaultAlerts.map((alert, index) => (
              <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='mb-0 me-2'>
                    <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      {alert.title}
                    </a>
                    <div className='text-gray-400 fs-7'>{alert.description}</div>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
