import {useIntl} from "react-intl"
import algeria_cities from "./algeria_cities.json"
import Creatable from "react-select/creatable"

const AlgeriaWilayaSelect = ({formik}) => {
  const intl = useIntl()
  const filterUniqueWilayas = (algeria_cities) => {
    const namesSet = new Set()
    return algeria_cities.filter((city) => {
      if (namesSet.has(city.wilaya_name_ascii)) {
        return false // Filter out if name already exists
      }
      namesSet.add(city.wilaya_name_ascii)
      return true // Keep the object if name is unique
    })
  }

  const wilayas = filterUniqueWilayas(algeria_cities)
  const wilayaSelectFormat = wilayas.map((wilaya) => {
    return {
      label: wilaya.wilaya_name_ascii,
      value: wilaya.wilaya_name_ascii,
    }
  })
  return (
    <>
      <Creatable
        {...formik.getFieldProps("state")}
        classNames={{
          control: (state) =>
            state.isFocused
              ? "form-control form-control-solid border-none box-shadow-none"
              : "form-control form-control-solid border-secondary",
          option: () => "bg-light-primary text-dark rounded-1 ",
        }}
        options={wilayaSelectFormat}
        placeholder={intl.formatMessage({id: "demand.step3_doc_state"})}
        // value={data.country}
        onChange={(e: any) => formik.setFieldValue("state", e)}
        isClearable
      />
    </>
  )
}

export default AlgeriaWilayaSelect
