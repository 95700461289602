/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from "react"
import {Link} from "react-router-dom"
import {useAuth} from "../../../../app/modules/auth"
import {Languages} from "./Languages"
import {toAbsoluteUrl} from "../../../helpers"
import {useIntl} from "react-intl"

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const intl = useIntl()
  const isVerified = currentUser.isVerified

  return (
    <div
      className='fixed-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px mx-5'>
            <img alt='Logo' src={toAbsoluteUrl("/media/avatars/blank.png")} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5 text-capitalize'>
              {currentUser?.fname} {currentUser?.lname}
              {isVerified ? (
                <span className='badge badge-light-success fw-bold fs-8 px-2 py-1 mx-2'>
                  {intl.formatMessage({id: "topbar.usermenu_verified"})}
                </span>
              ) : (
                <span className='badge badge-light-danger fw-bold fs-8 px-2 py-1 mx-2'>
                  {intl.formatMessage({id: "topbar.usermenu_not_verified"})}
                </span>
              )}
            </div>
            <a href='#' className='fw-semibold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <Link to={"/crafted/pages/profile"} className='menu-link px-5'>
          {intl.formatMessage({id: "topbar.usermenu_demands"})}
        </Link>
      </div> */}

      {/* <div className='menu-item px-5'>
        <Link to='/crafted/pages/profile/projects' className='menu-link px-5'>
          {intl.formatMessage({id: "topbar.usermenu_results"})}
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to='/' className='menu-link px-5'>
          {intl.formatMessage({id: "topbar.usermenu_Paiement"})}
        </Link>
      </div> */}

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/account/overview' className='menu-link px-5'>
          {intl.formatMessage({id: "account.overview_title"})}
        </Link>
      </div>
      <div className='menu-item px-5 my-1'>
        <Link to='/account/settings' className='menu-link px-5'>
          {intl.formatMessage({id: "topbar.usermenu_paramètres"})}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({id: "topbar.usermenu_logout"})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
