import {createContext, useContext, useState} from "react"
import {useQuery} from "react-query"
import {fetchDemands} from "./_requests"
import {AxiosResponse} from "axios"

type QueryContextType = {
  data: AxiosResponse<any> | null
  isLoading: boolean
  isError: boolean
  setPage: any
  page: number
}

const QueryContextInit = {
  data: null,
  isLoading: false,
  isError: false,
  setPage: () => {},
  page: 1,
}

const QueryContext = createContext<QueryContextType>(QueryContextInit)

export const QueryContextProvider = ({children}) => {
  const [page, setPage] = useState(1)

  const {data, isLoading, isError} = useQuery({
    queryKey: [`demands-page-${page}`],
    queryFn: () => fetchDemands(page),
  })

  return (
    <QueryContext.Provider value={{data, isLoading, isError, page, setPage}}>
      {children}
    </QueryContext.Provider>
  )
}

export const useQueryContext = () => {
  return useContext(QueryContext)
}
