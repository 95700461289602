import {createRoot} from "react-dom/client"
import {Chart, registerables} from "chart.js"
import {QueryClient, QueryClientProvider} from "react-query"
import {ReactQueryDevtools} from "react-query/devtools"
import {MetronicI18nProvider} from "./_metronic/i18n/Metronici18n"
import "./_metronic/assets/fonticon/fonticon.css"
import "./_metronic/assets/keenicons/duotone/style.css"
import "./_metronic/assets/keenicons/outline/style.css"
import "./_metronic/assets/keenicons/solid/style.css"
import "./_metronic/assets/sass/style.scss"
import "./_metronic/assets/sass/plugins.scss"
import "./_metronic/assets/sass/style.react.scss"
import {AppRoutes} from "./app/routing/AppRoutes"
import {AuthProvider} from "./app/modules/auth"
import "./myassets/styles/custom.css"

Chart.register(...registerables)

// sorry future unfortunate devs who have to work on this  :(

const queryClient = new QueryClient()
const container = document.getElementById("root")
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
