import React, {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react"
import {LayoutSplashScreen} from "../../../../_metronic/layout/core"
import {AuthModel, UserModel} from "./_models"
import * as authHelper from "./AuthHelpers"
import {WithChildren} from "../../../../_metronic/helpers"
import {getUserInfo, logoutApi} from "./_requests"
import {ConfirmationResult} from "firebase/auth"

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: any | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  confirm: ConfirmationResult | null
  setConfirm: Dispatch<SetStateAction<ConfirmationResult | null>>
  logout: () => void
  setToken: Dispatch<SetStateAction<string | null>>
  token: string | null
  setPhone: Dispatch<SetStateAction<string | null>>
  phone: string | null
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  confirm: null,
  setConfirm: () => {},
  logout: () => {},
  setToken: () => {},
  token: null,
  setPhone: () => {},
  phone: null,
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuthManually] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [confirm, setConfirm] = useState<ConfirmationResult | null>(null)
  const [token, setToken] = useState<null | string>(null)
  const [phone, setPhone] = useState<null | string>(null)

  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    // setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    setAuthManually(undefined)
    logoutApi()
      .then(() => {
        saveAuth(undefined)
        setCurrentUser(undefined)
      })
      .catch((err) => console.log(err))
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        setConfirm,
        confirm,
        setToken,
        token,
        phone,
        setPhone,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, saveAuth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // getting user info from localStorage
  useEffect(() => {
    const requestUpdatedUser = async () => {
      try {
        if (auth) {
          const response = await getUserInfo()
          if (response.status === 200) {
            const currentAuth = {
              user: response.data.data,
              token: auth.token,
            }
            saveAuth(currentAuth)
            setCurrentUser(currentAuth.user)
          }
        }

        //  setCurrentUser(auth?.user)
      } catch (error) {
        console.error(error)
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth) {
      requestUpdatedUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
