import {useEffect, useState} from "react"
import {useIntl} from "react-intl"
import {useNavigate} from "react-router-dom"
import * as Yup from "yup"
import {useAuth} from "../core/Auth"
import {useFormik} from "formik"
import {PasswordMeterComponent} from "../../../../_metronic/assets/ts/components"
import clsx from "clsx"
import {completeAccount} from "../core/_requests"
import {UserModel} from "../core/_models"
import Select from "react-select"
import i18nIsoCountries from "i18n-iso-countries"
import AlgeriaWilayaSelect from "../../../helpers/AlgeriaWilayaSelect"
import AlgeriaCommuneSelect from "../../../helpers/AlgeriaCommuneSelect"
import {motion} from "framer-motion"
import {NULL} from "sass"

var moment = require("moment")

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json"))

const countryOptions = Object.keys(i18nIsoCountries.getNames("fr")).map((countryCode) => ({
  label: i18nIsoCountries.getName(countryCode, "fr"),
  value: i18nIsoCountries.getName(countryCode, "fr"),
}))

const CompleteAccount = () => {
  const {setCurrentUser} = useAuth()
  const intl = useIntl()
  const initialValues = {
    fname: "",
    lname: "",
    gender: "",
    email: "",
    dob: "",
    country: {label: "Algérie", value: "Algérie"},
    state: {label: "", value: ""},
    county: {label: "", value: ""},
    address: "",
    ville: "",
    commune: "",
  }

  const registrationSchema = Yup.object().shape({
    fname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, {
        message: intl.formatMessage({id: "account.settings_validation_fname_alphabet_only"}),
      })
      .min(3, intl.formatMessage({id: "minimum"}))
      .max(20, intl.formatMessage({id: "maximum"}))
      .required(intl.formatMessage({id: "required"})),
    lname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, {
        message: intl.formatMessage({id: "account.settings_validation_lname_alphabet_only"}),
      })
      .min(3, intl.formatMessage({id: "minimum"}))
      .max(20, intl.formatMessage({id: "maximum"}))
      .required(intl.formatMessage({id: "required"})),
    gender: Yup.string().required(intl.formatMessage({id: "signup.validation.gender"})),

    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "L'adresse e-mail n'est pas valide."
      )
      .email(intl.formatMessage({id: "email"}))
      .max(50, intl.formatMessage({id: "maximum"}))
      .required(intl.formatMessage({id: "required"})),

    dob: Yup.date().required(intl.formatMessage({id: "dateOfBirthRequired"})),

    country: Yup.object().required("Le pays est requis").typeError("erreur"),
    state: Yup.object().when("country", {
      is: (e) => e?.label === "Algérie",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    county: Yup.object().when("country", {
      is: (e) => e?.label === "Algérie",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    ville: Yup.string().when("country", {
      is: (e) => e?.label !== "Algérie",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    commune: Yup.string().when("country", {
      is: (e) => e?.label !== "Algérie",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  })

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {saveAuth, logout} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const formData = new FormData()
      formData.append("fname", values.fname)
      formData.append("lname", values.lname)
      formData.append("email", values.email)
      formData.append("gender", values.gender)
      formData.append("dob", moment(values.dob).format("YYYY-MM-DD"))
      if (values.country) formData.append("country", values.country.value)
      if (values.country.value === "Algérie") {
        if (values.state) formData.append("state", values.state.value)
        if (values.county) formData.append("county", values.county.value)
      } else {
        if (values.ville) formData.append("state", values.ville)
        if (values.commune) formData.append("county", values.commune)
      }

      formData.append("address", values.address)
      console.log("test data before send", values)
      setLoading(true)
      try {
        completeAccount(formData)
          .then((res) => {
            // if (res.status === 200 || res.status === 201 ) {
            // navigate("/auth")
            // saveAuth(res.data)
            setCurrentUser(res.data.data as UserModel)
          })
          .catch((err) => {
            setStatus(`Les détails de l'inscription sont incorrects,
        l'e-mail doivent être uniques
        `)
            setSubmitting(false)
            setLoading(false)
          })
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(`Les détails de l'inscription sont incorrects
        l'e-mail doivent être uniques
        `)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const handleCancel = () => {
    logout()
    navigate("/auth")
  }
  const validateAlphabets = (value: string) => {
    if (value !== "") {
      if (!/^[A-Za-z\s]+$/.test(value)) {
        return "Veuillez entrer uniquement des lettres de l'alphabet."
      }
    }
  }

  return (
    <motion.div initial={{opacity: 0, y: -10}} whileInView={{opacity: 1, y: 0}}>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: "complete"})}</h1>
          {/* end::Title */}

          <div className='text-gray-500 fw-semibold fs-6 bg-light-warning p-4'>
            {intl.formatMessage({id: "completeMessage"})}
          </div>
        </div>
        {/* end::Heading */}

        {/* begin::Form group Firstname */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: "signup.firstname"})}
          </label>
          <input
            // onChange={(e) => {
            //   const value = e.target.value
            //   validateAlphabets(value)
            // }}
            placeholder={intl.formatMessage({id: "signup.firstname"})}
            id='fname'
            type='text'
            {...formik.getFieldProps("fname")}
            className={clsx("form-control bg-transparent")}
          />
          {formik.touched.fname && formik.errors.fname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.fname}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group Firstname */}

        {/* begin::Form group Lastname */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: "signup.lastname"})}
          </label>
          <input
            id='lname'
            placeholder={intl.formatMessage({id: "signup.lastname"})}
            type='text'
            {...formik.getFieldProps("lname")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.lname && formik.errors.lname,
              },
              {
                "is-valid": formik.touched.lname && !formik.errors.lname,
              }
            )}
          />
          {formik.touched.lname && formik.errors.lname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lname}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6' htmlFor='dob'>
            {intl.formatMessage({id: "gender"})}
          </label>
          <select
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.gender && formik.errors.gender,
              },
              {
                "is-valid": formik.touched.gender && !formik.errors.gender,
              }
            )}
            {...formik.getFieldProps("gender")}
          >
            <option value=''>Select</option>
            <option value='male'>{intl.formatMessage({id: "male"})}</option>
            <option value='female'>{intl.formatMessage({id: "female"})}</option>
          </select>
          {formik.errors.gender && formik.errors.gender && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.gender}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Form group dob */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6' htmlFor='dob'>
            {intl.formatMessage({id: "signup.dob"})}
          </label>
          <input
            id='dob'
            type='date'
            {...formik.getFieldProps("dob")}
            className={clsx(
              "form-control bg-transparent",
              {"is-invalid": formik.touched.dob && formik.errors.dob},
              {
                "is-valid": formik.touched.dob && !formik.errors.dob,
              }
            )}
          />
          {formik.touched.dob && formik.errors.dob && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.dob}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Country */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className=''>{intl.formatMessage({id: "signup.country"})}</span>
          </label>
          <Select
            {...formik.getFieldProps("country")}
            name='country'
            options={countryOptions}
            isClearable
            placeholder={intl.formatMessage({id: "signup.country_placeholder"})}
            // value={formik.values.country}
            classNames={{
              control: (state) =>
                state.isFocused
                  ? "form-control form-control-solid border-none box-shadow-none"
                  : "form-control form-control-solid border-secondary",
              option: () => "bg-light-primary text-dark rounded-1 ",
            }}
            onChange={(e) => formik.setFieldValue("country", e)}
            defaultValue={initialValues.country}
          />

          <div className='text-warning'>{intl.formatMessage({id: "required"})}</div>
        </div>

        {formik?.values?.country.label === "Algérie" ? (
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className=''>Wilaya</span>
            </label>
            <AlgeriaWilayaSelect formik={formik} />
            <div className='text-warning'>{intl.formatMessage({id: "required"})}</div>
          </div>
        ) : (
          <div className='fv-row mb-10'>
            <label className='form-label'>Wilaya</label>
            <input
              {...formik.getFieldProps("ville")}
              type='text'
              className={clsx(
                "form-control bg-transparent",
                {"is-invalid": formik.touched.ville && formik.errors.ville},
                {
                  "is-valid": formik.touched.ville && !formik.errors.ville,
                }
              )}
              placeholder='Wilaya'
            />
            <div className='text-danger mt-2'>
              <div className='text-warning'>{intl.formatMessage({id: "required"})}</div>
            </div>
          </div>
        )}
        {formik?.values?.country.label === "Algérie" ? (
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className=''>Commune</span>
            </label>
            <AlgeriaCommuneSelect formik={formik} wilaya={formik?.values?.state?.value} />
            <div className='text-warning'>{intl.formatMessage({id: "required"})}</div>
          </div>
        ) : (
          <>
            <div className='fv-row mb-10'>
              <label className='form-label'>Commune</label>
              <input
                defaultValue=''
                {...formik.getFieldProps("commune")}
                type='text'
                className={clsx(
                  "form-control bg-transparent",
                  {"is-invalid": formik.touched.commune && formik.errors.commune},
                  {
                    "is-valid": formik.touched.commune && !formik.errors.commune,
                  }
                )}
                placeholder='Commune'
              />
              <div className='text-danger mt-2'>
                <div className='text-warning'>{intl.formatMessage({id: "required"})}</div>
              </div>
            </div>
          </>
        )}
        {/*
        {/* end::Form group country */}

        {/* begin::Form group State */}

        {/* <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6 text-capitalize' htmlFor='county'>
            {intl.formatMessage({id: "signup.state"})}
          </label>
          <AlgeriaWilayaSelect formik={formik} />
          <div className='text-warning'>{intl.formatMessage({id: "required"})}</div>
        </div>

        {/* end::Form group */}

        {/* begin::Form group county */}
        {/*<div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6 text-capitalize' htmlFor='county'>
            {intl.formatMessage({id: "signup.county"})}
          </label>
          <AlgeriaCommuneSelect formik={formik} wilaya={formik?.values?.state?.value} />
          <div className='text-warning'>{intl.formatMessage({id: "required"})}</div>
        </div> */}
        {/* end::Form group */}

        {/* begin::Form group Address */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6' htmlFor='address'>
            {intl.formatMessage({id: "signup.address"})}
          </label>
          <input
            id='address'
            placeholder={intl.formatMessage({id: "signup.address"})}
            type='text'
            {...formik.getFieldProps("address")}
            className={clsx(
              "form-control bg-transparent",
              {"is-invalid": formik.touched.address && formik.errors.address},
              {
                "is-valid": formik.touched.address && !formik.errors.address,
              }
            )}
          />
          {formik.touched.address && formik.errors.address && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.address}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group phone */}
        {/* <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6' htmlFor='phone'>
          {intl.formatMessage({id: "signup.phone"})}
        </label>
        <input
          id='phone'
          placeholder={intl.formatMessage({id: "signup.phone"})}
          type='text'
          {...formik.getFieldProps("phone")}
          className={clsx(
            "form-control bg-transparent",
            {"is-invalid": formik.touched.phone && formik.errors.phone},
            {
              "is-valid": formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div> */}
        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6' id='email'>
            {intl.formatMessage({id: "signup.email"})}
          </label>
          <input
            placeholder={intl.formatMessage({id: "signup.email"})}
            type='email'
            id='email'
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control bg-transparent",
              {"is-invalid": formik.touched.email && formik.errors.email},
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group submit*/}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: "demand.buttons_valider"})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: "block"}}>
                {intl.formatMessage({id: "pleaseWait"})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
            onClick={handleCancel}
          >
            {intl.formatMessage({id: "cancel"})}
          </button>
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
        </div>
        {/* end::Form group submit */}
      </form>
    </motion.div>
  )
}

export default CompleteAccount
