/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import {useState} from "react"
import * as Yup from "yup"
import clsx from "clsx"
import {Link, useNavigate} from "react-router-dom"
import {useFormik} from "formik"
import {login} from "../core/_requests"
import {useAuth} from "../core/Auth"
import {AuthModel, UserModel} from "../core/_models"
import {useIntl} from "react-intl"
import {resendOtpApi} from "../../accounts/components/settings/cards/_requests"
import {motion} from "framer-motion"

const initialValues = {
  phone: "",
  password: "",
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()

  const loginSchema = Yup.object().shape({
    phone: Yup.string()
      .min(10, intl.formatMessage({id: "wrongPhone"}))
      .max(10, intl.formatMessage({id: "wrongPhone"}))
      .required(intl.formatMessage({id: "required"})),
    password: Yup.string()
      .min(8, intl.formatMessage({id: "validation.passwordMin"}))
      .max(50, intl.formatMessage({id: "validation.passwordMax"}))
      .required(intl.formatMessage({id: "validation.passwordRequired"})),
  })

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.phone, values.password)
        if (!auth) {
          setSubmitting(false)
          setLoading(false)
          throw new Error("no auth was received")
        }
        if (auth.data.user.role === "patient") {
          saveAuth(auth.data as AuthModel)

          if (!auth.data.user.isVerified) {
            console.warn("not verified")
            setSubmitting(false)
            setLoading(false)
            try {
              resendOtpApi(auth.data.user.phone)
              navigate("/auth/verify")
            } catch (error) {
              console.log(error)
            }
          } else if (!auth.data.user.isComplete) {
            console.warn("not verified")
            setSubmitting(false)
            setLoading(false)

            // something
            navigate("/auth/complete-account")
          } else {
            setCurrentUser(auth.data.user as UserModel)
          }
        } else {
          setSubmitting(false)
          setLoading(false)
          setStatus(intl.formatMessage({id: "unauthorisedLoginDetails"}))
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({id: "login.errorMessage"}))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <motion.div initial={{opacity: 0, y: -10}} whileInView={{opacity: 1, y: 0}}>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: "login.title"})}</h1>
          <div className='text-gray-500 fw-semibold fs-6'>
            {intl.formatMessage({id: "login.subtitle"})}
          </div>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: "login.phoneLabel"})}
          </label>
          <input
            placeholder={intl.formatMessage({id: "login.phoneLabel"})}
            {...formik.getFieldProps("phone")}
            className={clsx(
              "form-control bg-transparent",
              {"is-invalid": formik.touched.phone && formik.errors.phone},
              {
                "is-valid": formik.touched.phone && !formik.errors.phone,
              }
            )}
            type='string'
            name='phone'
            autoComplete='on'
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'> {formik.errors.phone}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-2'>
            {intl.formatMessage({id: "login.passwordLabel"})}
          </label>
          <input
            type='password'
            placeholder={intl.formatMessage({id: "login.passwordPlaceholder"})}
            autoComplete='on'
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          {/* begin::Link */}
          <Link to='/auth/forgot-password' className='link-primary'>
            {intl.formatMessage({id: "login.forgotPasswordLink"})}
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: "login.continueButton"})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: "block"}}>
                {intl.formatMessage({id: "login.waitMessage"})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          {intl.formatMessage({id: "login.notAMemberMessage"})}{" "}
          <Link to='/auth/registration' className='link-primary ps-2'>
            {intl.formatMessage({id: "login.inscription"})}{" "}
          </Link>
        </div>
      </form>
    </motion.div>
  )
}
