import axiosInstance from "../axios"

const getAllConversationsApi = async () => {
  return axiosInstance.get("/allConversations")
}

const getConversationApi = async (page) => {
  return axiosInstance.get(`/patient/messages?page=${page}`)
}

const getMessages = async () => {
  return axiosInstance.get("/allMessages")
}

const sendMessageApi = async (data) => {
  return axiosInstance.post("/patient/messages/send", data)
}

export {getMessages, sendMessageApi, getAllConversationsApi, getConversationApi}
