import {useState} from "react"
import OtpInput from "react-otp-input"
import {Link} from "react-router-dom"
import {useIntl} from "react-intl"
import {useAuth} from "../core/Auth"
import Countdown from "react-countdown"
import {motion} from "framer-motion"
import {resendOtpApi, verifyOtpApi} from "../core/_requests"
import toast from "react-hot-toast"

export default function OtpPage() {
  const intl = useIntl()
  const [otp, setOtp] = useState("")
  const [verified, setVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isTimeout, setIsTimeout] = useState(false)

  const {confirm, token, phone} = useAuth()

  const handleClick = async () => {
    try {
      if (token) {
        setLoading(true)
        await verifyOtpApi({otp, token})
          .then(async () => {
            setVerified(true)
            setLoading(false)
            setError(false)
          })
          .catch((err) => {
            setLoading(false)
            setError(true)
            console.log(err)
          })
      } else {
        console.log("no token : ", token)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const resendOTP = async () => {
    try {
      await resendOtpApi(phone)
      toast.success("otp.success")
    } catch (error) {
      toast.error("otp.error")
      console.log(error)
    }
  }

  return (
    <motion.div initial={{opacity: 0, y: -10}} whileInView={{opacity: 1, y: 0}}>
      <h1 className='mt-10 fw-bold  text-center '>{intl.formatMessage({id: "verify"})}</h1>
      <p className='mb-10  text-center text-muted '>
        {intl.formatMessage({id: "verifyPhoneNumber"})}
      </p>
      <div className='d-flex justify-content-center flex-column align-items-center mb-6'>
        {!verified && (
          <>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span style={{padding: "4px"}}></span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: "40px",
                height: "40px",
                fontSize: "20px",
                borderRadius: "10px",
                border: "2px solid #5a889e",
                color: "#5a889e",
              }}
              shouldAutoFocus
            />
            <div id='recaptcha'></div>
            <button className='btn btn-primary mt-6' onClick={handleClick}>
              {!loading && (
                <span className='indicator-label'>{intl.formatMessage({id: "verify"})}</span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: "block"}}>
                  {intl.formatMessage({id: "pleaseWait"})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </>
        )}
        {verified && (
          <>
            <div className='alert alert-success' role='alert'>
              {intl.formatMessage({id: "phoneVerified"})}
            </div>
            <Link to='/auth/login'>
              <button className='btn btn-primary'> {intl.formatMessage({id: "signIn"})}</button>
            </Link>
          </>
        )}

        <Countdown
          autoStart
          onComplete={() => setIsTimeout(true)}
          date={Date.now() + 6000}
          className='mt-10'
        />

        <span
          onClick={resendOTP}
          className={`fw-bolder fs-5 mt-6 ${
            isTimeout ? "opacity-100 cursor-pointer" : "opacity-50"
          }`}
        >
          {intl.formatMessage({id: "resendOtp"})}
        </span>
        {error && (
          <>
            <div className='alert alert-danger mt-5' role='alert'>
              {intl.formatMessage({id: "invalidOTP"})}
            </div>
          </>
        )}
      </div>
    </motion.div>
  )
}
