/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react"
import axiosInstance from "../../../../../apis/axios"
import {Card, Row} from "react-bootstrap"
import {useIntl} from "react-intl"

export function DemandResult({param1, param2}) {
  const [pdfBlob, setPdfBlob] = useState(null)
  const intl = useIntl()
  const API_URL = process.env.REACT_APP_API_URL
  const Result = `${API_URL}/results`
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axiosInstance.get(`${Result}/${param1}/${param2}`, {
          responseType: "blob",
        })
        setPdfBlob(response.data)
      } catch (error) {
        console.error("Error fetching PDF:", error)
      }
    }
    fetchPdf()
  }, [param1, param2])
  if (pdfBlob) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{maxWidth: "100%", maxHeight: "100%", width: "90%", height: "90vh"}}>
          {pdfBlob && (
            <embed
              src={URL.createObjectURL(pdfBlob)}
              type='application/pdf'
              width='100%'
              height='100%'
            />
          )}
        </div>
      </div>
    )
  } else {
    return (
      <Card>
        <Card.Body className='d-flex flex-column justify-content-center align-items-center'>
          <Row md={12}>
            <Card.Title>
              <h2>
                {intl.formatMessage({
                  id: "demand.ResultatsPage",
                })}
              </h2>
              <p></p>
            </Card.Title>
          </Row>

          <Row md={12}>
            <img
              src={require("../../../../../../myassets/4-dark.png")}
              alt=''
              className='w-75 h-50 mx-auto my-7'
            />
          </Row>
        </Card.Body>
      </Card>
    )
  }
}
