import algeria_cities from "./algeria_cities.json"
import {useMemo} from "react"
import {useIntl} from "react-intl"
import Creatable from "react-select/creatable"

const AlgeriaCommuneSelect = ({formik, wilaya}) => {
  const intl = useIntl()

  const filterUniqueCities = (algeria_cities) => {
    const namesSet = new Set()

    return algeria_cities.filter((city) => {
      if (namesSet.has(city.commune_name_ascii)) {
        return false // Filter out if name already exists
      }
      namesSet.add(city.commune_name_ascii)
      return true // Keep the object if name is unique
    })
  }

  const filteredSpecificCities = (wilaya) => {
    // get only specific communes by wilaya if wilaya is passed

    const uniqueCities = filterUniqueCities(algeria_cities)

    if (!wilaya) return uniqueCities
    return uniqueCities.filter((city) => city.wilaya_name_ascii === wilaya)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cities = useMemo(() => filteredSpecificCities(wilaya), [wilaya])

  const selectCummunes = cities.map((commune) => {
    return {
      label: commune.commune_name_ascii,
      value: commune.commune_name_ascii,
    }
  })
  return (
    <>
      <Creatable
        {...formik.getFieldProps("county")}
        classNames={{
          control: (state) =>
            state.isFocused
              ? "form-control form-control-solid border-none box-shadow-none"
              : "form-control form-control-solid border-secondary",
          option: () => "bg-light-primary text-dark rounded-1 ",
        }}
        options={selectCummunes}
        placeholder={intl.formatMessage({id: "demand.step3_doc_county"})}
        isClearable
        // value={data.country}
        onChange={(e: any) => formik.setFieldValue("county", e)}
      />
    </>
  )
}

export default AlgeriaCommuneSelect
