import React from "react"
import {useIntl} from "react-intl"
import {PageTitle} from "../../../_metronic/layout/core"
import {
  // StatisticsWidget2,
  StatisticsWidget5,
  // TilesWidget4,
} from "../../../_metronic/partials/widgets"
import {motion} from "framer-motion"

const DashboardPage = () => {
  // const {currentUser} = useAuth()
  const intl = useIntl()
  return (
    <motion.div initial={{opacity: 0.1}} animate={{opacity: 1}}>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <div className='row gx-5 gx-xl-6 mb-5 mb-xl-8'>
            <motion.div
              initial={{opacity: 0, x: -20}}
              animate={{opacity: 1, scale: 1, x: 0}}
              transition={{duration: 0.5}}
              className='col-xl-3 col-12 col-sm-6 mb-5 '
            >
              {/* <TilesWidget4 className='bg-primary' /> */}
              <StatisticsWidget5
                url='/new-demand'
                className='card-xl-stretch mb-xl-8 style-inside green-hover-background'
                color=''
                description='dashboard.new_demand_desc'
                descriptionColor='white'
                iconColor='white'
                svgIcon='plus'
                title='dashboard.new_demand'
                titleColor='white'
              />
            </motion.div>

            <motion.div
              initial={{opacity: 0, x: -20}}
              animate={{opacity: 1, scale: 1, x: 0}}
              transition={{duration: 0.5, delay: 0.1}}
              className='col-xl-3 col-12 col-sm-6 mb-5'
            >
              <StatisticsWidget5
                url='/dossier/demands'
                className='card-xl-stretch mb-xl-8 style-inside green-hover-background'
                color=''
                description='dashboard.dossier_desc'
                descriptionColor='white'
                iconColor='white'
                svgIcon='folder'
                title='dashboard.dossier_title'
                titleColor='white'
              />
            </motion.div>

            <motion.div
              initial={{opacity: 0, x: -20}}
              animate={{opacity: 1, scale: 1, x: 0}}
              transition={{duration: 0.5, delay: 0.2}}
              className='col-xl-3 col-12 col-sm-6 mb-5'
            >
              <StatisticsWidget5
                url='/paiement'
                className='card-xl-stretch mb-xl-8 style-inside green-hover-background'
                color=''
                description='dashboard.paiement_desc'
                descriptionColor='white'
                iconColor='white'
                svgIcon='two-credit-cart'
                title='dashboard.paiement_title'
                titleColor='white'
              />
            </motion.div>

            <motion.div
              initial={{opacity: 0, x: -20}}
              animate={{opacity: 1, scale: 1, x: 0}}
              transition={{duration: 0.5, delay: 0.3}}
              className='col-xl-3 col-12 col-sm-6 mb-5'
            >
              <StatisticsWidget5
                url='/chat'
                className='card-xl-stretch mb-xl-8 style-inside green-hover-background'
                color=''
                description='dashboard.messages_desc'
                descriptionColor='white'
                iconColor='white'
                svgIcon='messages'
                title='dashboard.messages_title'
                titleColor='white'
              />
            </motion.div>
          </div>

          <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
            <section>
              <header className='my-5'>
                <h1 className='text-primary fs-3'>
                  {intl.formatMessage({id: "dashboard.footer_title"})}{" "}
                </h1>
                <h3 className='fs-5'>{intl.formatMessage({id: "dashboard.footer_desc"})}</h3>
              </header>
            </section>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: "dashboard"})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
