/* eslint-disable jsx-a11y/anchor-is-valid */

import {useIntl} from "react-intl"

type Dropdown1Type = {
  filter?: string
  setFilter?: any
  className?: string
}

export function Dropdown1({filter, setFilter}: Dropdown1Type) {
  const intl = useIntl()

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bold'>{intl.formatMessage({id: "filter.title"})}</div>
      </div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-semibold'>
            {intl.formatMessage({id: "filter.status"})}
          </label>
          <div>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder="Sélectionnez l'option"
              data-allow-clear='true'
            >
              <option value=''>{intl.formatMessage({id: "filter.noFilter"})}</option>
              <option value='1'>{intl.formatMessage({id: "filter.pending"})}</option>
              <option value='2'>{intl.formatMessage({id: "filter.accepted"})}</option>
              <option value='3'>{intl.formatMessage({id: "filter.cancelled"})}</option>
              <option value='4'>{intl.formatMessage({id: "filter.rejected"})}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
