import {useEffect, useState} from "react"
import {Link, useLocation} from "react-router-dom"
import {reset, verifyExistence} from "../core/_requests"
import * as Yup from "yup"
import {useFormik} from "formik"
import clsx from "clsx"
import {PasswordMeterComponent} from "../../../../_metronic/assets/ts/components"
import {useIntl} from "react-intl"
import {motion} from "framer-motion"

export function ResetPassword() {
  const location = useLocation()
  const intl = useIntl()
  const searchParams = new URLSearchParams(location.search)
  const [loading, setLoading] = useState(true)
  const [exists, setExists] = useState(false)
  const email = searchParams.get("email")!
  const token = searchParams.get("token")!
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, intl.formatMessage({id: "passwordMin"}))
      .max(50, intl.formatMessage({id: "passwordMax"}))
      .required(intl.formatMessage({id: "passwordRequired"})),
    password_confirmation: Yup.string()
      .min(8, intl.formatMessage({id: "passwordConfirmationMin"}))
      .max(50, intl.formatMessage({id: "passwordConfirmationMax"}))
      .required(intl.formatMessage({id: "passwordConfirmationRequired"}))
      .oneOf([Yup.ref("password")], intl.formatMessage({id: "passwordMismatch"})),
  })

  const [wentWrong, setWentWrong] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      setSubmitting(true)

      try {
        reset(email, token, values.password, values.password_confirmation)
          .then((res) => {
            setSubmitting(false)
            setSuccess(true)
            setWentWrong(false)
            if (res.status >= 300) {
              setWentWrong(true)
            }
          })
          .catch((err) => {
            setSubmitting(false)

            setWentWrong(true)

            console.log(err)
          })
      } catch (err) {
        console.log(err)
        setSubmitting(false)

        setWentWrong(true)
      }
    },
  })
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  useEffect(() => {
    setLoading(true)

    verifyExistence(email, token)
      .then((res) => {
        if (res.status === 200) {
          setExists(true)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
        setExists(false)
        setLoading(false)
      })
  }, [email, token])

  if (success && !wentWrong)
    return (
      <motion.div initial={{opacity: 0, y: -10}} whileInView={{opacity: 1, y: 0}}>
        <h1>{intl.formatMessage({id: "resetPassword"})}</h1>
        <>
          <div className='alert alert-success text-center my-6 d-flex align-items-center gap-5 justify-content-between'>
            {intl.formatMessage({id: "passwordUpdated"})}
          </div>
          <Link to='/auth'>
            <button className='btn btn-lg btn-primary w-100 mb-5'>
              {" "}
              {intl.formatMessage({id: "signIn"})}
            </button>
          </Link>
        </>
      </motion.div>
    )

  return (
    <motion.div initial={{opacity: 0, y: -10}} whileInView={{opacity: 1, y: 0}}>
      <h1>{intl.formatMessage({id: "resetPassword"})}</h1>

      <>
        {loading && (
          <>
            <div className='alert alert-info text-center my-6 d-flex align-items-center gap-5 justify-content-between'>
              {intl.formatMessage({id: "emailVerification"})}
              <div className='spinner-border text-info' role='status'>
                <span className='visually-hidden'> {intl.formatMessage({id: "loading"})}</span>
              </div>
            </div>
          </>
        )}

        {!loading && !exists && (
          <>
            <div className='alert alert-danger text-center my-6 d-flex align-items-center gap-5 justify-content-between'>
              {intl.formatMessage({id: "expiredToken"})}
            </div>
            <Link to='/auth'>
              <button className='btn btn-lg btn-primary w-100 mb-5'>
                {intl.formatMessage({id: "tryAgain"})}
              </button>
            </Link>
          </>
        )}
        {!loading && exists && (
          <>
            <div className='alert alert-success text-center my-6 d-flex align-items-center gap-5 justify-content-between'>
              {intl.formatMessage({id: "emailVerified"})}
            </div>
            <form className='my-10' onSubmit={formik.handleSubmit}>
              <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                  <label className='form-label fw-bolder text-dark fs-6' htmlFor='password'>
                    {intl.formatMessage({id: "password"})}
                  </label>
                  <div className='position-relative mb-3'>
                    <input
                      id='password'
                      type='password'
                      placeholder='Mot de passe'
                      autoComplete='off'
                      {...formik.getFieldProps("password")}
                      className={clsx(
                        "form-control bg-transparent",
                        {
                          "is-invalid": formik.touched.password && formik.errors.password,
                        },
                        {
                          "is-valid": formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='text-muted'>{intl.formatMessage({id: "passwordCriteria"})}</div>
              </div>

              <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  {intl.formatMessage({id: "confirmPassword"})}
                </label>
                <input
                  type='password'
                  placeholder='Password confirmation'
                  id='password_confirmation'
                  {...formik.getFieldProps("password_confirmation")}
                  className={clsx(
                    "form-control bg-transparent",
                    {
                      "is-invalid":
                        formik.touched.password_confirmation && formik.errors.password_confirmation,
                    },
                    {
                      "is-valid":
                        formik.touched.password_confirmation &&
                        !formik.errors.password_confirmation,
                    }
                  )}
                />
                {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password_confirmation}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!submitting && (
                    <span className='indicator-label'>
                      {intl.formatMessage({id: "demand.buttons_valider"})}
                    </span>
                  )}
                  {submitting && (
                    <span className='indicator-progress' style={{display: "block"}}>
                      {intl.formatMessage({id: "passwordReset.loadingMessage"})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </>
        )}
      </>
    </motion.div>
  )
}
