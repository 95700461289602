/* eslint-disable no-unreachable */
import React, {FC} from "react"
import {KTIcon} from "../../../helpers"
// import {Action} from "../../content/activity/Action"
import {ActivitiesContextProvider, useActivitiesContext} from "./core/ActivitiesContext"
// import {Loading} from "../../../../app/modules/apps/user-management/users-list/components/loading/Loading"
import ActivitiesPagination from "./ActivitiesPagination"
import {Loading} from "../../../layout/components/loading/Loading"
import {Action} from "../../content/activity/Action"
import {useIntl} from "react-intl"

const ActivityDrawer = () => {
  return (
    <ActivitiesContextProvider>
      <ActivityDrawerInner />
    </ActivitiesContextProvider>
  )
}

const ActivityDrawerInner: FC = () => {
  const {response, isFetching, refetch} = useActivitiesContext()
  const {formatMessage} = useIntl()
  const handleRefresh = () => {
    refetch()
  }

  return (
    <div
      id='kt_activities'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='activities'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_activities_toggle'
      data-kt-drawer-close='#kt_activities_close'
    >
      <div className='card w-100 shadow-none rounded-0'>
        <div className='card-header' id='kt_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>{formatMessage({id: "log.title"})}</h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-light-info mx-10'
              onClick={handleRefresh}
            >
              <KTIcon iconName='arrow-circle-right' className='fs-1' />
            </button>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-light-danger me-n5'
              id='kt_activities_close'
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </div>

        <div className='card-body position-relative' id='kt_activities_body'>
          <div
            id='kt_activities_scroll'
            className='position-relative scroll-y me-n5 pe-5 w-100'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_activities_body'
            data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
            data-kt-scroll-offset='5px'
          >
            <div className='timeline '>
              {isFetching && (
                <div className='w-100 text-center'>
                  <Loading />
                </div>
              )}

              {!isFetching && response && (
                <>
                  {response.data.map((action) => (
                    <div key={action.id}>
                      <Action action={action} />
                    </div>
                  ))}
                  {response && <ActivitiesPagination meta={response.meta} />}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {ActivityDrawer}
