/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from "react"
import {useFormik} from "formik"
import * as Yup from "yup"
import clsx from "clsx"
import {register} from "../core/_requests"
import {Link, useNavigate} from "react-router-dom"
import {PasswordMeterComponent} from "../../../../_metronic/assets/ts/components"
import {useAuth} from "../core/Auth"
import {useIntl} from "react-intl"
import {motion} from "framer-motion"

const initialValues = {
  fname: "",
  lname: "",
  phone: "",
  dob: "",
  country: "",
  county: "",
  state: "",
  address: "",
  email: "",
  password: "",
  password_confirmation: "",
  gender: "",
}

export function Registration() {
  const intl = useIntl()

  const registrationSchema = Yup.object().shape({
    phone: Yup.string()
      .min(10, intl.formatMessage({id: "wrongPhone"}))
      .max(10, intl.formatMessage({id: "wrongPhone"}))
      .required(intl.formatMessage({id: "required"})),
    password: Yup.string()
      .min(8, intl.formatMessage({id: "minimum"}))
      .max(50, intl.formatMessage({id: "maximum"}))
      .required(intl.formatMessage({id: "required"})),
    password_confirmation: Yup.string()
      .min(8, intl.formatMessage({id: "minimum"}))
      .max(50, intl.formatMessage({id: "maximum"}))
      .required(intl.formatMessage({id: "required"}))
      .oneOf([Yup.ref("password")], intl.formatMessage({id: "mismatch"})),
  })

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {setToken, setPhone} = useAuth()

  const handleRegister = async (phone: string, formData: any, actions) => {
    await register(formData)
      .then(async (registerRes) => {
        console.log("registerRes", registerRes)
        const phoneNumber = `+213${String(phone)?.substring(1)}`
        setPhone(phoneNumber)
        setToken(registerRes.data.data?.token)
        navigate("/auth/verify")
      })
      .catch((err) => {
        console.log("Error while registering : ", err)
        actions.setStatus(intl.formatMessage({id: "registration.error"}))
        setLoading(false)
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const formData = new FormData()
      formData.append("phone", values.phone)
      formData.append("password", values.password)
      formData.append("password_confirmation", values.password_confirmation)

      setLoading(true)
      try {
        await handleRegister(values.phone, values, {setStatus, setSubmitting})
          .then(async (res) => {})
          .catch((err) => {
            setStatus(intl.formatMessage({id: "registration.error"}))
            setSubmitting(false)
            setLoading(false)
          })
      } catch (error) {
        setStatus(intl.formatMessage({id: "registration.error"}))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <motion.div initial={{opacity: 0, y: -10}} whileInView={{opacity: 1, y: 0}}>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: "signup.title"})}</h1>
          {/* end::Title */}

          <div className='text-gray-500 fw-semibold fs-6'>
            {intl.formatMessage({id: "signup.createAccount"})}
          </div>
        </div>
        {/* end::Heading */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group phone */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6' htmlFor='phone'>
            {intl.formatMessage({id: "signup.phone"})}
          </label>
          <input
            id='phone'
            placeholder={intl.formatMessage({id: "signup.phone"})}
            type='text'
            {...formik.getFieldProps("phone")}
            className={clsx(
              "form-control bg-transparent",
              {"is-invalid": formik.touched.phone && formik.errors.phone},
              {
                "is-valid": formik.touched.phone && !formik.errors.phone,
              }
            )}
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phone}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6' htmlFor='password'>
              {intl.formatMessage({id: "signup.password"})}
            </label>
            <div className='position-relative mb-3'>
              <input
                id='password'
                type='password'
                placeholder={intl.formatMessage({id: "signup.password"})}
                autoComplete='off'
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid": formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid": formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            {intl.formatMessage({id: "signup.passwordRequirements"})}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: "signup.confirmPassword"})}
          </label>
          <input
            type='password'
            placeholder={intl.formatMessage({id: "signup.confirmPassword"})}
            id='password_confirmation'
            {...formik.getFieldProps("password_confirmation")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid":
                  formik.touched.password_confirmation && formik.errors.password_confirmation,
              },
              {
                "is-valid":
                  formik.touched.password_confirmation && !formik.errors.password_confirmation,
              }
            )}
          />
          {formik.touched.password_confirmation && formik.errors.password_confirmation && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password_confirmation}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group submit*/}

        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: "demand.buttons_valider"})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: "block"}}>
                {intl.formatMessage({id: "pleaseWait"})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              {intl.formatMessage({id: "cancel"})}
            </button>
          </Link>
        </div>
        {/* end::Form group submit */}
      </form>
    </motion.div>
  )
}
