/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useMemo, useState} from "react"
import clsx from "clsx"
import Pusher from "pusher-js"
import isOwner from "../../helpers/isOwner"
import {getConversationApi, sendMessageApi} from "../../../app/apis"
import {useIntl} from "react-intl"
import moment from "moment"
import InfiniteScroll from "react-infinite-scroll-component"
import {Spinner} from "react-bootstrap"
import {toAbsoluteUrl} from "../../helpers"
import {useAuth} from "../../../app/modules/auth"
import toast from "react-hot-toast"
import {motion} from "framer-motion"
// type User = {
//   user: {
//     id: string
//     fname: string
//     lname: string
//     email: string
//     phone: string
//     dob: string
//     address: string
//     county: string
//     state: string
//     country: string
//     type: string
//     role: string
//     isVerified: boolean
//     isComplete: boolean
//   }
// }

type Message = {
  id: string
  text: string
  sender: {
    id: string
    name: string
    surname: string
  }
  receiver: {
    id: string
    name: string
    surname: string
  }
  date: Date
  type: string
}

type PusherEventData = {
  data: Message
}

type Props = {
  isDrawer?: boolean
  isLoading?: boolean
  messages: Message[]
}

const ChatInner: FC<Props> = ({isDrawer = false, messages, isLoading}) => {
  const [chatMessages, setChatMessages] = useState(messages)
  const [message, setMessage] = useState<string>("")
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(messages?.length >= 30)
  const intl = useIntl()
  const isRtl = intl.locale === "ar"
  const {currentUser} = useAuth()
  const [receiverID, setReceiverID] = useState<null | string>()

  const getReceiverID = () => {
    const firstMessage = MESSAGES?.find(
      (message) =>
        message?.sender?.id === currentUser?.id || message?.receiver?.id === currentUser?.id
    )

    // Extract the receiver ID from the first message
    const res = firstMessage
      ? firstMessage?.sender?.id === currentUser?.id
        ? firstMessage?.receiver?.id
        : firstMessage?.sender?.id
      : null

    setReceiverID(res)
  }

  const sendMessage = async (e) => {
    e?.preventDefault()
    if (message.length < 1) {
      toast.error(intl.formatMessage({id: "chat.alert"}))
    } else {
      try {
        setLoading(true)
        await sendMessageApi({
          receiver: receiverID,
          text: message,
        }).then(() => {
          const newMessage = {
            id: String(Math.random()),
            text: message,
            sender: {
              id: currentUser.id,
              name: "YourName",
              surname: "YourSurname",
            },
            receiver: {
              id: receiverID as string,
              name: "ReceiverName",
              surname: "ReceiverSurname",
            },
            me: true,
            date: new Date(),
            type: "text",
          }
          setChatMessages((prev) => [newMessage, ...prev])
          setMessage("")
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log("error while sending message : ", error)
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const MESSAGES = useMemo(() => chatMessages, [chatMessages, messages])

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e?.keyCode === 13 && e?.shiftKey === false) {
      e?.preventDefault()
      sendMessage(e)
    }
  }

  const loadMoreOlderMessages = async () => {
    const nextPage = page + 1
    try {
      const response = await getConversationApi(nextPage)
      const newMessages = response?.data?.data || []

      if (newMessages.length > 0) {
        const transformedMessages = newMessages.map((message: Message) => ({
          ...message,
          text: message.text,
        }))
        setChatMessages((prev) => [...prev, ...transformedMessages])
        setPage(nextPage)
      } else {
        setHasMore(false)
        setPage(page) // Revert to the previous page if there are no new messages
      }
    } catch (error) {
      console.error("Error while loading more messages:", error)
      setPage(page) // Revert to the previous page in case of an error
      setHasMore(false)
    }
  }

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY as string, {
      cluster: process.env.REACT_APP_PUSHER_HOST as string,
    })
    const channel = pusher.subscribe(`${currentUser.id}-messages-channel`)
    channel.bind(`center-message`, (data: PusherEventData) => {
      setChatMessages((prev) => [data.data, ...prev])
      setReceiverID(data.data.sender.id)
    })
    return () => {
      pusher.unsubscribe(`${currentUser.id}-messages-channel`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getReceiverID()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const MemorizedMessages = useMemo(
    () => (
      <div
        id='chat-wrapper'
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={MESSAGES?.length ? MESSAGES.length : 0} //This is important field to render the next data
          next={loadMoreOlderMessages}
          inverse={true}
          hasMore={hasMore}
          scrollableTarget='chat-wrapper'
          loader={
            <div className='w-100 d-flex justify-content-center align-items-center'>
              <Spinner animation='border' className=' mx-auto' />
            </div>
          }
          height={350}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <>
            {MESSAGES?.length > 0 ? (
              <>
                {MESSAGES?.map((message, index) => {
                  const type = isOwner(currentUser, message?.sender) ? false : true
                  const state = type ? "light" : "primary"

                  const parsedDate = moment(message?.date, "YYYY-DD-MM HH:mm:ss")

                  const today = moment()

                  // Check if the date is today
                  const isToday = parsedDate.isSame(today, "day")

                  // Format the date based on whether it's today or not
                  const formattedDate = isToday
                    ? parsedDate.format("HH:mm")
                    : parsedDate.format("MMM DD [at] HH:mm")

                  const templateAttr = {}
                  const contentClass = `${isDrawer ? "" : "d-flex"} justify-content-${
                    isRtl ? (type ? "end" : "start") : type ? "start" : "end"
                  } mb-10`
                  return (
                    <motion.div
                      initial={{opacity: 0.1, y: 20}}
                      whileInView={{opacity: 1, y: 0}}
                      key={`message${index}`}
                      className={clsx("d-flex", contentClass, "mb-10")}
                      {...templateAttr}
                    >
                      <div
                        className={clsx(
                          "d-flex flex-column align-items",
                          `align-items-${type ? "start" : "end"}`
                        )}
                      >
                        <motion.div
                          initial={{opacity: 0.1, y: 20}}
                          whileInView={{opacity: 1, y: 0}}
                          className={clsx(
                            "p-5 rounded",
                            `bg-${state}`,
                            "text-dark  fw-semibold mw-lg-400px",
                            `text-${type ? "start" : "end"}`
                          )}
                          id={`message-chat-${state}`}
                          data-kt-element='message-text'
                          dangerouslySetInnerHTML={{__html: message?.text}}
                        ></motion.div>
                        <span className='text-muted fs-7 my-2'>
                          {/* {message?.date === "envoyé à l'instant" */}
                          {/* ? "Envoyé à l'instant" */}
                          {formattedDate}
                        </span>
                      </div>
                    </motion.div>
                  )
                })}
              </>
            ) : (
              <div className='card flex-lg-row-fluid ms-lg-7 mx-xl-10'>
                <div className='card-header  p-7 '>
                  <h1 className='text-center w-100 my-5 fw-bold'>
                    {intl.formatMessage({id: "chat.infoTitle"})}
                  </h1>
                  <p className='text-muted text-center w-100 my-5 fw-bold'>
                    {intl.formatMessage({id: "chat.infoParagraph"})}
                  </p>
                </div>
                <div className='card-body d-flex justify-content-center justify-content-center'>
                  <img
                    src={toAbsoluteUrl("/media/illustrations/downloaded/chat.png")}
                    width={400}
                    alt=''
                  />
                </div>
              </div>
            )}
          </>
        </InfiniteScroll>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [MESSAGES, hasMore, chatMessages]
  )

  return (
    <div
      className='card-body'
      id={isDrawer ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"}
    >
      <div
        className={clsx("scroll-y me-n5 pe-5", {"h-300px h-lg-auto": !isDrawer})}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? "#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
            : "#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer,  #kt_chat_messenger_footer"
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? "#kt_drawer_chat_messenger_body"
            : "#kt_content, #kt_app_content, #kt_chat_messenger_body"
        }
        data-kt-scroll-offset={isDrawer ? "0px" : "5px"}
      >
        {isLoading ? (
          <div
            // key={`message${index}`}
            className={clsx("d-flex", "mb-10")}
          >
            <div
              className={clsx(
                "d-flex flex-column align-items"
                // `align-items-${type ? "start" : "end"}`
              )}
            ></div>
          </div>
        ) : (
          MemorizedMessages
        )}
      </div>

      <div
        className='card-footer pt-4'
        id={isDrawer ? "kt_drawer_chat_messenger_footer" : "kt_chat_messenger_footer"}
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          data-kt-element='input'
          placeholder={intl.formatMessage({id: "chat.inputPlaceHolder"})}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        <div className='d-flex flex-stack'>
          <form>
            <div className='d-flex align-items-center me-2'></div>
            <button
              className='btn btn-primary'
              type='button'
              data-kt-element='send'
              onClick={sendMessage}
              disabled={loading}
            >
              {loading && (
                <>
                  <span className='indicator-progress' style={{display: "block"}}>
                    <span className='spinner-border spinner-border-sm align-middle mx-2'></span>
                  </span>
                </>
              )}
              {!loading && intl.formatMessage({id: "chat.buttonSend"})}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export {ChatInner}
