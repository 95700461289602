/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTIcon} from "../../../../../../_metronic/helpers"
import {useQueryContext} from "../core/QueryContextProvider"

type Props = {
  current_page: number
  from: number
  last_page: number
  per_page: number
  to: number
  total: number
}

const Pagination = ({data}) => {
  const {page, setPage} = useQueryContext()

  const meta: Props = data?.meta

  if (!meta) return <></>

  const handlePreviousClick = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  const handleNextClick = () => {
    if (page < meta.last_page) {
      setPage((prev) => prev + 1)
    }
  }

  return (
    <div>
      <ul className='pagination'>
        <li className={`page-item previous ${page === 1 ? "disabled" : ""}`}>
          <button className='page-link' onClick={handlePreviousClick}>
            <KTIcon
              iconName={document.documentElement.dir === "rtl" ? "arrow-right" : "arrow-left"}
              className='fs-3 mx-2 '
            />
          </button>
        </li>

        <li className='page-item'>
          <button className={`page-link active bg-hover-secondary`}>{meta.current_page}</button>
        </li>
        <li className='page-item'>
          <button
            className={`page-link ${meta.current_page + 1 > meta.last_page ? "disabled" : ""}`}
            onClick={() => setPage(meta.current_page + 1)}
          >
            {meta.current_page + 1}
          </button>
        </li>
        <li className='page-item'>
          <button
            className={`page-link ${meta.current_page + 2 > meta.last_page ? "disabled" : ""}`}
            onClick={() => setPage(meta.current_page + 2)}
          >
            {meta.current_page + 2}
          </button>
        </li>

        <li className={`page-item next ${page === meta.last_page ? "disabled" : ""}`}>
          <button className='page-link' onClick={handleNextClick}>
            <KTIcon
              iconName={document.documentElement.dir === "rtl" ? "arrow-left" : "arrow-right"}
              className='fs-3 mx-2 '
            />
          </button>
        </li>
      </ul>
    </div>
  )
}

export default Pagination
