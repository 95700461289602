import {Route, Routes} from "react-router-dom"
import {Registration} from "./components/Registration"
import {ForgotPassword} from "./components/ForgotPassword"
import OtpPage from "./components/OtpPage"
import {Login} from "./components/Login"
import {AuthLayout} from "./AuthLayout"
import {ResetPassword} from "./components/ResetPassword"
import CompleteAccount from "./components/CompleteAccount"

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='verify' element={<OtpPage />} />
      <Route path='complete-account' element={<CompleteAccount />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
