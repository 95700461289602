import axiosInstance from "./Axios"

export function updateProfileDetails() {
  return
}
export const verifyOtpApi = async (otp) => {
  return await axiosInstance.post(`/verify`, {code: otp})
}

export const resendOtpApi = (number) => {
  return axiosInstance.post(`/resend`, {phone: number})
}

export const updateAccountInfoApi = async (data) => {
  return await axiosInstance.post(`/my-account/update`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const updateAccountPasswordApi = async (data) => {
  return await axiosInstance.post(`/my-account/changePassword`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const disableAccountApi = async () => {
  return await axiosInstance.post(`/my-account/deactivate`)
}
