import {lazy, FC, Suspense} from "react"
import {Route, Routes, Navigate} from "react-router-dom"
import {MasterLayout} from "../../_metronic/layout/MasterLayout"
import TopBarProgress from "react-topbar-progress-indicator"
import {DashboardWrapper} from "../pages/dashboard/DashboardWrapper"
// import {MenuTestPage} from "../pages/MenuTestPage"
import {getCSSVariableValue} from "../../_metronic/assets/ts/_utils"
import {WithChildren} from "../../_metronic/helpers"
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper"
import DemandResultWrapper from "../modules/profile/components/Demands/demand-result/DemandResultWrapper"
// import {About} from "../pages/about/About"

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"))
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"))
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"))
  // const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"))
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"))
  // const UsersPage = lazy(() => import("../modules/apps/user-management/UsersPage"))
  const PaymentsPage = lazy(() => import("../modules/apps/payments/PaymentsPage"))
  const PaymentsResulsPage = lazy(() => import("../modules/apps/payments/PaymentsResulsPage"))
  const PaymentsConfirmPage = lazy(() => import("../modules/apps/payments/PaymentsConfirmPage"))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* <Route path='/chat' element={<ChatPage />} /> */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* <Route path='about' element={<About />} /> */}
        {/* Lazy Modules */}
        <Route
          path='dossier/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='result/*'
          element={
            <SuspensedView>
              <DemandResultWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='new-demand/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='/paiement'
          element={
            <SuspensedView>
              <PaymentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/paiement/results'
          element={
            <SuspensedView>
              <PaymentsResulsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/paiement/confirm'
          element={
            <SuspensedView>
              <PaymentsConfirmPage />
            </SuspensedView>
          }
        />
        <Route
          path='/chat'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='/chat/:id/:name'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue("--bs-primary")
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
