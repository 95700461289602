/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import {KTIcon} from "../../../helpers"
import {Link} from "react-router-dom"
import {useIntl} from "react-intl"
type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description?: string
  descriptionColor?: string
  url?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  url,
}) => {
  const intl = useIntl()
  return (
    <Link
      to={`${url}`}
      className={`card bg-primary ${className}`}
      // className={clsx({card: mode})}
      // style={{backgroundColor: '#376eaf'}}
      data-bs-theme='light'
      style={{minHeight: "184px"}}
      // style{{maxHeight: '150px', backgroundColor: '#376eaf}}}
    >
      <div className='card-body  '>
        <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />

        <h1 className={`text-${titleColor} fw-bold fs-3 mb-2 mt-1`}>
          {intl.formatMessage({id: title})}
        </h1>

        <div className={`fw-semibold text-${descriptionColor}`}>
          {" "}
          {intl.formatMessage({id: description})}
        </div>
      </div>
    </Link>
  )
}

export {StatisticsWidget5}
